import { SelectProps } from 'antd';
import { capitalCase } from 'change-case';

import { SearchResult } from 'types';
import SearchLabel from './SearchLabel';

const getObjectUrl = (searchResult: SearchResult) => {
    if (searchResult.type === 'person') {
        return `/people/${searchResult.id}`;
    }

    switch (searchResult.type) {
        case 'zone':
            return `/zoneChart/${searchResult.id}#zone:${searchResult.id}`;
        case 'team':
            return `/teams/${searchResult.id}`;
        case 'decision':
        case 'objective':
        case 'role':
            return `/teams/${searchResult.team_id}#${searchResult.type}:${searchResult.id}`;
    }
    return `/${searchResult.type}s/${searchResult.id}`;
};

const resultsToOptionGroup = (results: SearchResult[]) => {
    return results.map((result) => {
        return {
            value: getObjectUrl(result),
            label: <SearchLabel result={result} />,
        };
    }) as SelectProps['options'];
};

export const resultsToOptions = (results: SearchResult[]) => {
    const resultMap = new Map<string, SearchResult[]>();

    for (const result of results) {
        const values = resultMap.get(result.type) || [];
        values.push(result);
        resultMap.set(result.type, values);
    }

    const options = [];
    for (const type of Array.from(resultMap.keys())) {
        const resultsForType = resultMap.get(type);
        if (resultsForType) {
            options.push({
                label: <span>{capitalCase(type)}</span>,
                title: type,
                options: resultsToOptionGroup(resultsForType),
            });
        }
    }
    return options as SelectProps['options'];
};
