import apiClient from 'api';
import PeopleInfoCard from 'components/cards/PeopleInfoCard';
import React from 'react';
import { Card, Typography, Space, Descriptions, DescriptionsProps, App } from 'antd';

import { getErrorMessage, map } from 'utils';
import type { BaseObject, ObjectPictures, Person } from 'types';
import { AppDispatch, useAppDispatch } from 'store';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePeople } from 'hooks/usePeople';
import EditableAvatar from 'components/avatar/EditableAvatar';
import { setCurrentUser } from 'features/currentUserSlice';
import DefaultHomePageCard from './DefaultHomePageCard';

const ProfilePage = () => {
    const { message } = App.useApp();
    const currentUser = useCurrentUser();

    /* This redundancy forces a re-render on people data update. */
    const people = map(usePeople());
    const dispatch: AppDispatch = useAppDispatch();

    if (!currentUser) return null;
    const reportsTo = currentUser.reports_to_id ? people[currentUser.reports_to_id] : null;

    const items: DescriptionsProps['items'] = [
        {
            key: 'email',
            label: 'Email',
            children: <Typography.Text>{currentUser.email}</Typography.Text>,
        },
        {
            key: 'firstName',
            label: 'First Name',
            children: <Typography.Text>{currentUser.first_name}</Typography.Text>,
        },
        {
            key: 'lastName',
            label: 'Last Name',
            children: <Typography.Text>{currentUser.last_name}</Typography.Text>,
        },
    ];

    if (currentUser.title) {
        items.push({
            key: 'title',
            label: 'Title',
            children: <Typography.Text>{currentUser.title}</Typography.Text>,
        });
    }

    if (reportsTo) {
        items.push({
            key: 'reportsTo',
            label: 'Reports To',
            children: <Typography.Link href={`/people/${reportsTo.id}`}>{reportsTo.name}</Typography.Link>,
        });
    }

    items.push({
        key: 'userType',
        label: 'User Type',
        children: <Typography.Text>{currentUser.user_type}</Typography.Text>,
    });

    async function onAvatarChange(obj: BaseObject & ObjectPictures) {
        try {
            const person = await apiClient.patch<Person>('/auth/me', obj);
            dispatch(setCurrentUser(person));
        } catch (e) {
            message.error(getErrorMessage(e));
        }
        return true;
    }

    return (
        <Space direction={'vertical'} size={'middle'} style={{ width: '100%' }}>
            <Card>
                <Space align={'center'} size={'large'}>
                    <EditableAvatar size={64} obj={currentUser} updateObject={onAvatarChange} />
                    <Space direction={'vertical'}>
                        <Typography.Title level={2} style={{ margin: 0 }}>
                            {currentUser.name}
                        </Typography.Title>
                        {currentUser.title ? <Typography.Text>{currentUser.title}</Typography.Text> : null}
                    </Space>
                </Space>
            </Card>
            <Card>
                <Descriptions size={'small'} items={items} column={1} styles={{ label: { alignItems: 'center' } }} />
            </Card>
            <PeopleInfoCard person={currentUser} />
            <DefaultHomePageCard />
        </Space>
    );
};

export default ProfilePage;
